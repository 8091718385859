<script>
import { getBanner, deleteBanner } from '@//api/admin/settings'
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'

import Dropdown from '@/components/ui/Dropdown.vue'
import AddBtn from '@/components/ui/AddBtn.vue' // 등록

export default {
  name: '',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    Dropdown
  },
  provide: {
    grid: [Page, CommandColumn, Sort, Toolbar, Search]
  },
  data() {
    return {
      currentPath: this.$route.fullPath, // 현재 접속 경로
      dataSource: [],
      commands: [{ buttonOption: { content: '수정', cssClass: 'e-info' } }, { buttonOption: { content: '삭제', cssClass: 'e-danger' } }],
      toolbarOptions: ['Search'],
      pageSettings: { pageCount: 10, pageSize: 10 },
      persistedData: [],
      searchConditions: [
        { value: 'bannerId', text: '번호' },
        { value: 'title', text: '제목' },
        { value: 'link', text: '링크' }
      ],
      searchBy: ''
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.setSearchOptions()
    this.loadData()
  },
  unmounted() {},
  methods: {
    write() {
      this.$router.push(`${this.currentPath}/w`)
    },
    commandClick(e) {
      if (e.target.classList.contains('e-info')) return this.modifyContent(e)
      if (e.target.classList.contains('e-danger')) return this.delContent(e)
    },
    modifyContent(e) {
      const id = e.rowData.bannerId
      this.$router.push(`${this.currentPath}/w/${id}`)
    },
    async delContent(e) {
      if (!confirm('정말로 삭제하시겠습니까?')) return

      const id = e.rowData.bannerId

      // 삭제 api 호출
      const result = await deleteBanner(id)
      if (result?.code) return alert(result.message)

      // 삭제 성공 callback
      const gridInstance = this.$refs.grid.ej2Instances
      gridInstance.dataSource = this.setDataSource(id)
      alert('삭제되었습니다.')
    },
    setDataSource(id) {
      this.dataSource = this.dataSource.filter((item) => item.bannerId !== id)
      return this.dataSource
    },
    async loadData() {
      this.dataSource = (await getBanner()).map((item) => {
        return { ...item, startDate: this.$getDate(item.startDate), endDate: this.$getDate(item.endDate) }
      })

      this.persistedData = this.dataSource
    },
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      this.data = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    }
  }
}
</script>

<template>
  <div id="content-wrapper" class="d-flex flex-column">
    <AddBtn @click="write()"></AddBtn>
    <Dropdown
      class="select-wrapper"
      :options="searchConditions"
      :disabled="false"
      name="zipcode"
      ref="selectOption"
      @selected="selectDropdownHandler"
    ></Dropdown>
    <div id="content">
      <div class="">
        <ejs-grid
          :dataSource="dataSource"
          :allowPaging="true"
          :pageSettings="pageSettings"
          :toolbar="toolbarOptions"
          :commandClick="commandClick"
          :actionComplete="actionComplete"
          ref="grid"
        >
          <e-columns>
            <e-column field="bannerId" headerText="번호" width="80" textAlign="center" :isPrimaryKey="true" />
            <e-column field="title" headerText="제목" width="250" textAlign="center" />

            <e-column
              field="startDate"
              headerText="시작일"
              width="150"
              editType="datetimepickeredit"
              textAlign="center"
              format="y/M/d "
              type="date"
            />
            <e-column
              field="endDate"
              headerText="종료일"
              width="150"
              editType="datetimepickeredit"
              textAlign="center"
              format="y/M/d "
              type="date"
            />
            <e-column field="link" headerText="링크" textAlign="center" />

            <e-column field="manage" headerText="관리" textAlign="center" :commands="commands" />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
